import React from 'react';
import Dashboard from './Dashboard';
import BackgroundImages from './BackgroundImages';

function App() {
  //return <Dashboard />;
  return ( <div className="App"> <BackgroundImages /> </div> );
}

export default App;
