import React from 'react';
import './DescriptionOverlay.css';

const DescriptionOverlay = () => {
  return (
    <div className="description-overlay">
      <h1>Max Caddie</h1>
      <p>
        Max Caddie is your personalized AI Golf Caddie, designed to accompany you on the golf course.
        With a unique personality tailored just for you, Max Caddie guarantees an enhanced golfing
        experience. Our advanced AI technology adapts to your play style, helping you make better
        decisions on the course and ultimately improving your game. Maximize your potential and
        enjoy the game like never before with Max Caddie by your side.
      </p>
    </div>
  );
};

export default DescriptionOverlay;
