import React from 'react';
import { AppBar ,Toolbar, Typography, Button, Container, Box, Grid, Paper } from '@mui/material';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';

const theme = createTheme({
  palette: {
    text: {
      secondary: '#32a852', // Replace this with the desired secondary color.
    },
  },
});

const StyledAppBar = styled(AppBar)`
  flex-grow: 1;
`;

const Title = styled(Typography)`
  flex-grow: 1;
`;

const StyledPaper = styled(Paper)`
  ${({ theme }) => `
    padding: ${theme.spacing(2)}px;
    textAlign: center;
    color: ${theme.palette.text.secondary};
  `}
`;


function Dashboard() {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <StyledAppBar position="static">
          <Toolbar>
            <Title variant="h6">Max Caddy Dashboard</Title>
            <Button color="inherit" startIcon={<LogoutIcon />}>
              Logout
            </Button>
          </Toolbar>
        </StyledAppBar>
        <Container>
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <StyledPaper elevation={3}>
                  <Typography variant="h6" gutterBottom>
                    <PersonIcon /> Caddie Personality
                  </Typography>
                  <Typography variant="body1">Name: John</Typography>
                  <Typography variant="body1">Voice: Male, British</Typography>
                  <Typography variant="body1">Humor Level: Moderate</Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper elevation={3}>
                  <Typography variant="h6" gutterBottom>
                    <GolfCourseIcon /> Current Round Status
                  </Typography>
                  <Typography variant="body1">Hole: 5</Typography>
                  <Typography variant="body1">Par: 4</Typography>
                  <Typography variant="body1">Score: +2</Typography>
                </StyledPaper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledPaper elevation={3}>
                  <Typography variant="h6" gutterBottom>
                    <ScatterPlotIcon /> Shot Statistics
                  </Typography>
                  <Typography variant="body1">Avg. Distance to Pin: 150 yds</Typography>
                  <Typography variant="body1">Avg. Miss Left/Right: 10 yds</Typography>
                  <Typography variant="body1">Dispersion Pattern: 20 yds</Typography>
                </StyledPaper>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default Dashboard;
