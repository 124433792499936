import React, { useState, useEffect, useCallback } from 'react';
import './BackgroundImages.css';
import DescriptionOverlay from './DescriptionOverlay';

const BackgroundImages = () => {
  const [images, setImages] = useState([]);
  const [imageCache, setImageCache] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [duration] = useState(2000); // Minimum display time for each image in milliseconds

  useEffect(() => {
    generateRandomSequence();
  }, []);

  const loadImage = useCallback(async (index) => {
    if (imageCache[images[index]]) {
      return;
    }
    const img = new Image();
    img.src = images[index];
    try {
      await img.decode();
      setImageCache((prevCache) => ({ ...prevCache, [images[index]]: true }));
      console.log(`Image ${index} loaded`);

      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length); // Cycle back to the first image when the last image has been displayed
      }, duration);
    } catch (e) {
      console.error(`Failed to load image ${index}:`, e);
    }
  }, [imageCache, images, duration]);

  useEffect(() => {
    if (currentIndex < images.length) {
      loadImage(currentIndex);
    }
  }, [currentIndex, images.length, loadImage]);

  const generateRandomSequence = () => {
    const totalImages = 48;
    const imageIndices = Array.from({ length: totalImages }, (_, i) => i);

    for (let i = totalImages - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [imageIndices[i], imageIndices[j]] = [imageIndices[j], imageIndices[i]];
    }

    setImages(imageIndices.map(index => `./images/image-${index + 1}.jpg`));
  };

  return (
    <div className="container">
      {images.map((image, index) => (
        <div
          key={index}
          className={`background-image ${currentIndex === index ? 'show' : ''}`}
          style={{ backgroundImage: `url(${image})` }}
        />
      ))}
      <DescriptionOverlay />
    </div>
  );
};

export default BackgroundImages;
